import React from 'react';
import FooterSticky from "./FooterSticky";
import TextField from '@mui/material/TextField';
import { style } from '@mui/system';


class FinishedEN extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errorBool: false,
            errorText: '',
            emailValue: '',
            userId: this.props.location.state.userId
        };
    }

    handleEmail(email) {

        console.log(email)

        if(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email)) {
            console.log("Works")
            document.getElementById("submitBtn").disabled = true;
            document.getElementById("submitBtn").innerText = "Submitted";
            document.getElementById("submitBtn").style.backgroundColor = "#6CCB69"
            this.setState({errorBool:false, emailValue: "", errorText: ""})
            this.sendDetailsToServer()
        }
        else{
            this.setState({errorBool:true, emailValue: email, errorText: "Invalid email address"})
        }
    }

    sendDetailsToServer() {
        //Specify the request options
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'user_id': this.state.userId,
                'email_id': this.state.email

            })
        };

        //Send the request to the server
        //fetch("http://127.0.0.1:4000/finish", requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/finish", requestOptions)
            .then(res => res.json())
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>End</h1>
                </div>

                <div>
                    <p className='text'>
                        You have completed the task and have ranked all pairs of flats. Thank you for your help!
                        <br/><br/>
                        If you are interested in obtaining your results or being informed about the further progress
                        of the study, please leave your email address below and we will send you your results and updates
                        about the study via email. Please note that your results can only be sent to you once the study has 
                        been finished which may take some weeks or months.
                        <br></br>
                        <br></br>
                        <TextField id="outlined-basic" label="Email" variant="outlined" defaultValue={this.state.emailValue} error={this.state.errorBool} helperText={this.state.errorText} onChange={(event) =>  this.state.email = event.target.value}/>
                        <br></br>
                        <br></br>
                        <button style={{marginLeft:'0px'}} className='button confirmButton' id="submitBtn" value="Submit" onClick={() => {
                            this.handleEmail(this.state.email)
                        }}>Submit
                        </button>

                        <br/><br/>
                        
                        You can now close this window.
                    </p>
                </div>

                <div style={{paddingBottom: '40px'}}>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default FinishedEN;