import React from 'react';
import {Link} from "react-router-dom";
import FooterSticky from "./FooterSticky";

class TaskOverviewDE extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.location.state.userId
        };
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>Aufgabenbeschreibung I</h1>
                </div>

                <div>
                    <p className='text'>
                        In dieser Aufgabe geht es darum, dass Du ein Immobilienmakler / eine Immobilienmaklerin bist und
                        eine passende Wohnung für eine Familie finden sollst.
                        Die Familie hat einige Präferenzen zu ihrer neuen Wohnung, welche Dir auf der nächsten Seite
                        präsentiert werden.
                        <br/><br/>
                        Deine Aufgabe ist es, aus Paaren von Wohnungen diejenige Wohnung auszusuchen,
                        die Deiner Meinung nach besser zu den Präferenzen der Familie passt.
                        Insgesamt werden Dir 55 Wohnungspaare gezeigt und Du musst bei jedem Paar die bessere Wohnung
                        auswählen.
                        <br/><br/>
                        Um fortzufahren, klicke bitte auf <b>Weiter</b>.
                    </p>
                </div>

                <div style={{paddingTop: '2%', paddingBottom: '10%', paddingLeft: '40px', height: '100%',}}>
                    <Link
                        to={{
                            pathname: '/taskDetailDE',
                            state:{
                                userId: this.state.userId
                            }
                        }}>
                        <button className='button goButton'>Weiter</button>
                    </Link>
                </div>
                <div style={{paddingBottom: '40px'}}>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default TaskOverviewDE;