import { fontWeight } from '@mui/system';
import React from 'react';
import {Link} from "react-router-dom";
import {getRandomArbitrary} from "../HelperFunctions";
import FooterSticky from "./FooterSticky";


class IntroductionEN extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: 0, //The user id
        };
    }

    /**
     * Life-cycle method that initializes the user Id
     */
     componentWillMount() {
        this.setState({userId: getRandomArbitrary(1000000, 2000000)});
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>Instructions</h1>
                </div>

                <div>
                    <p className='text'>
                        The aim of this study is to rank a set of items.
                        You will see two items each and your task is to choose the item that suits better to the task defined in the next page.
                        <br/><br/>
                        The results of this study will be used for further research purposes, including the creation of a final ranking of items.
                        We therefore ask you to read the instructions of the task carefully and to select the items carefully. You do not need to memorize the instructions of the task as they will be shown below each of the two items. You can take as much time as you need.
                        Please do not refresh the page during the user study. 
                        <br/><br/>
                        If you are participating in the study and would like to recieve money (in the form of a gift card from Digitec/Galaxus),
                        please note down the unique ID below or give your email ID at the end of the study. Please note that only those who
                       completely finish this user study will recieve the gift card and the amount you will recieve depends on your accuracy with respect to the task description. The maximum you can win is a 25 CHF gift card from Digitec/Galaxus.
                        <br/><br/>
                        <b>Unique ID: {this.state.userId}</b>
                        <br/><br/>
            
                        If you agree and would like to participate in the study, please click on the <b>Next</b> button
                        below.
                    </p>
                </div>

                <div style={{paddingTop: '0%', paddingBottom: '5%', paddingLeft: '40px', height: '100%',}}>
                    <Link
                        to={{
                            pathname: '/taskOverviewEN',
                            state:{
                                userId: this.state.userId
                            }
                        }}>
                        <button className='button goButton'>Next</button>
                    </Link>
                </div>
                <div>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default IntroductionEN;