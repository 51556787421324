import React from 'react';
import {Link} from "react-router-dom";
import {getRandomArbitrary} from "../HelperFunctions";
import FooterSticky from "./FooterSticky";

class IntroductionDE extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: 0, //The user id
        };
    }

    /**
     * Life-cycle method that initializes the user Id
     */
     componentWillMount() {
        this.setState({userId: getRandomArbitrary(1000000, 2000000)});
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>Instruktionen</h1>
                </div>

                <div>
                    <p className='text'>
                        In dieser Studie geht es darum, die Items eines Daten Sets in eine Reihenfolge zu bringen.
                        Dazu werden Dir jeweils zwei Items präsentiert und Deine Aufgabe ist es, das bessere Item
                        auszuwählen.
                        Anhand dieser Item Vergleiche wird dann eine Rangliste aller Items erstellt.
                        <br/><br/>
                        Die Ergebnisse dieser Studie werden für weitere Forschungszwecke verwendet.
                        Wir bitten Dich deshalb, die Instrukltionen dieser Studie gut durchzulesen und die Items
                        sorgfältig auszuwählen. Du kannst dir so viel Zeit nehmen für die Studie wie du möchtest. 
                        Bitte aktualisieren die Seite während der Benutzerstudie nicht.
                        <br/><br/>
                        Wenn Du an der Studie teilnehmen und Geld erhalten möchtest, notiere bitte die folgende eindeutige
                        ID und zeige sie uns in Raum BIN.2.A.22. Bitte beachten Sie, dass nur diejenigen, die die Benutzerstudie abschließen,
                        Anspruch auf eine Vergütung haben. Ausserdem kann die Prämie nur einmal pro Person 
                        ausbezahlt werden.
                        <br/><br/>
                        <b>Eindeutige ID: {this.state.userId}</b>
                        <br/><br/>
                        Wenn Du damit einverstanden bist und an der Studie teilnehmen möchtest, klicke bitte
                        auf <b>Weiter</b>.
                    </p>
                </div>

                <div style={{paddingTop: '2%', paddingBottom: '10%', paddingLeft: '40px', height: '100%',}}>
                    <Link
                        to={{
                            pathname: '/taskOverviewDE',
                            state:{
                                userId: this.state.userId
                            }
                        }}>
                        <button className='button goButton'>Weiter</button>
                    </Link>
                </div>
                <div style={{paddingBottom: '40px'}}>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default IntroductionDE;