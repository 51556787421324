import './App.css';
import {BrowserRouter as Router, Route, Redirect,} from "react-router-dom";
import TaskOverviewEN from "./components/TaskOverviewEN";
import TaskOverviewDE from "./components/TaskOverviewDE";
import TaskDetailDE from "./components/TaskDetailDE";
import TaskDetailEN from "./components/TaskDetailEN";
import PairwiseComparisonEN from "./components/PairwiseComparisonEN";
import PairwiseComparisonDE from "./components/PairwiseComparisonDE";
import Home from "./components/Home";
import IntroductionDE from "./components/IntroductionDE";
import IntroductionEN from "./components/IntroductionEN";
import ScrollToTop from "./ScrollToTop";
import FinishedEN from "./components/FinishedEN";
import FinishedDE from "./components/FinishedDE";
import PairwiseComparisonEN1 from './components/PairwiseComparisonEN1';
import PairwiseComparisonDE1 from './components/PairwiseComparisonDE1';

function App() {
    return (
        <div className='wholeApp'>
            <Router>
                <ScrollToTop>
                    <Route path="/taskOverviewEN" component={TaskOverviewEN}/>
                    <Route path="/taskOverviewDE" component={TaskOverviewDE}/>
                    <Route path="/introductionDE" component={IntroductionDE}/>
                    <Route path="/introductionEN" component={IntroductionEN}/>
                    <Route path="/taskDetailEN" component={TaskDetailEN}/>
                    <Route path="/taskDetailDE" component={TaskDetailDE}/>
                    <Route path="/pairwiseComparisonEN" component={PairwiseComparisonEN}/>
                    <Route path="/pairwiseComparisonDE" component={PairwiseComparisonDE}/>
                    <Route path="/finishedEN" component={FinishedEN}/>
                    <Route path="/finishedDE" component={FinishedDE}/>
                    <Route path='/home' component={Home}/>
                    <Route path="/pairwiseComparisonEN1" component={PairwiseComparisonEN1}/>
                    <Route path="/pairwiseComparisonDE1" component={PairwiseComparisonDE1}/>
                    <Route exact path="/">
                        <Redirect to="/home"/>
                    </Route>
                </ScrollToTop>
            </Router>
        </div>
    );
}

export default App;
