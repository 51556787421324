import React from 'react';
import FooterSticky from "./FooterSticky";
import TextField from '@mui/material/TextField';


class FinishedDE extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errorBool: false,
            errorText: '',
            emailValue: '',
            userId: this.props.location.state.userId
        };
    }

    handleEmail(email) {

        console.log(email)

        if(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email)) {
            console.log("Works")
            document.getElementById("submitBtn").disabled = true;
            document.getElementById("submitBtn").innerText = "Submitted";
            document.getElementById("submitBtn").style.backgroundColor = "#6CCB69"
            this.setState({errorBool:false, emailValue: "", errorText: ""})
            this.sendDetailsToServer()
        }
        else{
            this.setState({errorBool:true, emailValue: email, errorText: "Invalid email address"})
        }
    }

    sendDetailsToServer() {
        //Specify the request options
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'user_id': this.state.userId,
                'email_id': this.state.email

            })
        };

        //Send the request to the server
        //fetch("http://127.0.0.1:4000/finish", requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/finish", requestOptions)
            .then(res => res.json())
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>Ende</h1>
                </div>

                <div>
                    <p className='text'>
                        Du hast alle Wohnungspaare bearbeitet und die Aufgabe erfolgreich beendet. Vielen Dank für Deine Hilfe!
                        <br/><br/>
                        Wenn Du daran interessiert bist, Deine Resultate zu erhalten oder über den weiteren Verlauf der Studie
                        informiert werden möchtest, kannst Du unten Deine E-Mail-Adresse angeben. Wir werden Dir dann Deine Resultate
                        sowie Updates zur Studie per E-Mail senden. Bitte beachte, dass wir Dir die Resultate erst nach der Beendung der
                        Studie zusenden können (dies kann einige Wochen oder Monate dauern).
                        <br></br>
                        <br></br>
                        <TextField id="outlined-basic" label="Email" variant="outlined" defaultValue={this.state.emailValue} error={this.state.errorBool} helperText={this.state.errorText} onChange={(event) =>  this.state.email = event.target.value}/>
                        <br></br>
                        <br></br>
                        <button style={{marginLeft:'0px'}} className='button confirmButton' id="submitBtn" value="Submit" onClick={() => {
                            this.handleEmail(this.state.email)
                        }}>Submit
                        </button>

                        <br/><br/>
                        Du kannst dieses Fenster jetzt schliessen. 
                    </p>
                </div>

                <div style={{paddingBottom: '40px'}}>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default FinishedDE;