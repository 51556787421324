import React from 'react';
import Table from "react-bootstrap/Table";
import Footer from "./Footer";
import FooterSticky from "./FooterSticky";
import {getRandomArbitrary} from "../HelperFunctions";
import Slider from "@mui/material/Slider";


class PairwiseComparisonDE extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            counter: 1, //The counter that counts, how many item pairs have been processed
            itemPresent: false, //Boolean that indicates whether an item pair is present or not
            itemPair: [], //An item pair that was loaded from the server
            userId: this.props.location.state.userId, //The user id
            certainity: 50, // Certainity of choice
            completedTask: false, // Flag to check if user completed the task
            ipaddress: "undefined", // Initialize variable for storing IP address
            optionValue: 0, // Option value
            email_id: "NA"

        };
        this.getItemPair = this.getItemPair.bind(this)
        this.getItemPairUserCalibration = this.getItemPairUserCalibration.bind(this)

    }

    /**
     * Life-cycle method that initializes the user Id and starts the ranking task.
     */
     componentWillMount() {
        // this.setState({userId: getRandomArbitrary(1000000, 2000000)}, () => this.getItemPair());
        this.getItemPair();

    // Fetch IP address using external API (cannot do through local API)
    fetch("https://api.ipify.org?format=json&callback=getIP")
        .then((response) => response.json())
        .then(res => {
            this.state.ipaddress = String(res.ip)
        })
        .catch(err => console.log(err))
    }

    /**
     * Increases the counter that counts, how many item pairs have already been processed
     * If 50 items have been processed, the user is redirected to the end page
     */
    increaseCounter() {
        if (this.state.counter === 54)
        {
            this.state.completedTask = true
        }
        //Check if the user has already processed 50 pairs
        if (this.state.counter === 55) {
            //If yes, the user is redirected to the end page
            let path = {
                pathname: '/finishedDE',
                state:{
                    userId: this.state.userId
                }
            }
            this.props.history.push(path);
        } 
        if (((this.state.counter + 1) % 10) == 0) {
            console.log(this.state.counter)
            console.log("User calibration pair")
            this.setState({counter: this.state.counter + 1}, () => this.getItemPairUserCalibration())

        }
        else {
            console.log("Normal counter", this.state.counter)
            //Else, a new pair is requested from the server
            this.setState({counter: this.state.counter + 1}, () => this.getItemPair())
        }
    }

    /**
     * Request an item pair from the server.
     */
    getItemPair() {
        //Specify the options for the GET request
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        //Fetch the item pair. Be careful with the address and do not confuse localhost and 127.0.0.1
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/pair?userId=" + this.state.userId, requestOptions)
            .then(res => res.json())
            .then(
                //Set the item pair as state
                (result) => {
                    this.setState({
                        itemPresent: true,
                        itemPair: result.pair
                    });
                },
                //Handle errors
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    /**
     * Request an item pair from the server.
     */
     getItemPairUserCalibration() {
        //Specify the options for the GET request
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        console.log("Getting user calibration item")

    
        //Fetch the item pair. Be careful with the address and do not confuse localhost and 127.0.0.1
        // fetch("http://127.0.0.1:4000/UCpair?userId=" + this.state.userId, requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/UCpair?userId=" + this.state.userId, requestOptions)
            .then(res => res.json())
            .then(
                //Set the item pair as state
                (result) => {
                    console.log(result)
                    this.setState({
                        itemPresent: true,
                        itemPair: result.pair
                    });
                },
                //Handle errors
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    /**
     * When a user has clicked on the button of option , this method is called
     * @param optionValue Float that indicates whether the first (less than 0) or second item (greater than 0) in the pair was chosen
     */
     handleSelectItem() {
        this.setState({itemPresent: false},
            () => {
                if (this.state.optionValue < 0) {
                    let optionSelected = "Option A"
                    if (this.state.counter % 10 == 0) {
                        this.sendChoiceToServerUserCalibration(this.state.itemPair[0], this.state.itemPair[1], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                    else {
                        this.sendChoiceToServer(this.state.itemPair[0], this.state.itemPair[1], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                    
                } else if (this.state.optionValue >= 0) {
                    let optionSelected = "Option B"
                    if (this.state.counter % 10 == 0) {
                        this.sendChoiceToServerUserCalibration(this.state.itemPair[1], this.state.itemPair[0], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                    else {
                        this.sendChoiceToServer(this.state.itemPair[1], this.state.itemPair[0], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                }
            }
        )
    }

    /**
     * Sends the chosen item to the server for storing it in the database
     * @param picked the item that was picked by the user
     * @param notPicked the item that was not picked by the user
     * @param optionValue the value of the slider for selecting Option A or Option B
     * @param OptionSelected the string of option A or option B selected
     * @param certainity the value from the certainity slider
     */
     sendChoiceToServer(picked, notPicked, optionValue, optionSelected, certainity) {
        console.log("Now sending certainity value", this.state.certainity)
        //Specify the request options
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'userId': this.state.userId, 'itemIdPicked': picked.itemId,
                'itemIdNotPicked': notPicked.itemId,
                'optionValue': optionValue,
                'optionSelected': optionSelected,
                'certainitySlider': this.state.certainity,
                'completedTask': this.state.completedTask,
                'ipaddress': this.state.ipaddress,
                'email_id': this.state.email_id
            })
            //TODO: Should be POST and not PUT
        };

        //Send the request to the server
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/choice", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    //Increase the counter if the call was successful
                    this.increaseCounter();
                    // Reset sliders
                    this.state.certainity = 50
                    this.state.optionValue = 0
                },
                //Handle errors otherwise
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    /**
     * Sends the chosen item to the server for storing it in the database
     * @param picked the item that was picked by the user
     * @param notPicked the item that was not picked by the user
     */
     sendChoiceToServerUserCalibration(picked, notPicked, optionValue, optionSelected, certainity) {
        //Specify the request options
        console.log("Picked",picked.UCitemId)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'UCuserId': this.state.userId,
                'UCitemIdPicked': picked.UCitemId,
                'UCitemIdNotPicked': notPicked.UCitemId,
                'UCoptionValue': optionValue,
                'UCoptionSelected': optionSelected,
                "UCcertainitySlider": this.state.certainity
            })
            //TODO: Should be POST and not PUT
        };

        //Send the request to the server
        // fetch("http://127.0.0.1:4000/UCchoice", requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/UCchoice", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    //Increase the counter if the call was successful
                    this.increaseCounter();
                    // Reset sliders
                    this.state.certainity = 50
                    this.state.optionValue = 0
                },
                //Handle errors otherwise
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    render() {
        let table = [];

        const optionValueSliderMarks = [
            {
                value: -1,
                label: "Option A ist viel besser"
            },
            {
                value: -0.5,
                label: "Option A ist etwas besser"
            },
            {
                value: 0,
                label: "Beide Optionen sind gleich gut"
            },
            {
                value: 0.5,
                label: "Option B ist etwas besser"
            },
            {
                value: 0.99,
                label: "Option B ist viel besser"
            }
        ];

        const confidenceSliderMarks = [
            {
                value: 0,
                label: "Überhaupt nicht sicher"
            },
            {
                value: 50,
                label: "Mehr oder weniger sicher"
            },
            {
                value: 100,
                label: "Sehr sicher"
            }
        ];

        //Create the table that shows the two options
        console.log("Table counter", this.state.counter)
        if (this.state.itemPresent && ((this.state.counter % 10) == 0)) {
            let item1 = this.state.itemPair[0];
            let item2 = this.state.itemPair[1];
            table.push(
                <>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Lage</td>
                        <td className='backgroundCell1'>{item1.UCregio2}</td>
                        <td className='backgroundCell2'>{item2.UCregio2}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Mietkosten</td>
                        <td className='backgroundCell1'>{item1.UCbaseRent}</td>
                        <td className='backgroundCell2'>{item2.UCbaseRent}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Nebenkosten</td>
                        <td className='backgroundCell1'>{item1.UCserviceCharge}</td>
                        <td className='backgroundCell2'>{item2.UCserviceCharge}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Anzahl Zimmer</td>
                        <td className='backgroundCell1'>{item1.UCnoRooms}</td>
                        <td className='backgroundCell2'>{item2.UCnoRooms}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Wohnfläche</td>
                        <td className='backgroundCell1'>{item1.UClivingSpace}</td>
                        <td className='backgroundCell2'>{item2.UClivingSpace}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Stockwerk und Lift?</td>
                        <td className='backgroundCell1'>{item1.UCliftAndFloor}</td>
                        <td className='backgroundCell2'>{item2.UCliftAndFloor}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Zustand</td>
                        <td className='backgroundCell1'>{item1.UCcondition}</td>
                        <td className='backgroundCell2'>{item2.UCcondition}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Baujahr</td>
                        <td className='backgroundCell1'>{item1.UCyearConstructed}</td>
                        <td className='backgroundCell2'>{item2.UCyearConstructed}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Anzahl Parkplätze</td>
                        <td className='backgroundCell1'>{item1.UCnoParkSpaces}</td>
                        <td className='backgroundCell2'>{item2.UCnoParkSpaces}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Hat Balkon oder Garten?</td>
                        <td className='backgroundCell1'>{item1.UCgardenOrBalcony}</td>
                        <td className='backgroundCell2'>{item2.UCgardenOrBalcony}</td>
                    </tr>
                </>
            )
        }

        else if (this.state.itemPresent) {

            let item1 = this.state.itemPair[0];
            let item2 = this.state.itemPair[1];
            table.push(
                <>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Lage</td>
                        <td className='backgroundCell1'>{item1.regio2}</td>
                        <td className='backgroundCell2'>{item2.regio2}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Mietkosten</td>
                        <td className='backgroundCell1'>{item1.baseRent}</td>
                        <td className='backgroundCell2'>{item2.baseRent}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Nebenkosten</td>
                        <td className='backgroundCell1'>{item1.serviceCharge}</td>
                        <td className='backgroundCell2'>{item2.serviceCharge}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Anzahl Zimmer</td>
                        <td className='backgroundCell1'>{item1.noRooms}</td>
                        <td className='backgroundCell2'>{item2.noRooms}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Wohnfläche</td>
                        <td className='backgroundCell1'>{item1.livingSpace}</td>
                        <td className='backgroundCell2'>{item2.livingSpace}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Stockwerk und Lift?</td>
                        <td className='backgroundCell1'>{item1.liftAndFloor}</td>
                        <td className='backgroundCell2'>{item2.liftAndFloor}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Zustand</td>
                        <td className='backgroundCell1'>{item1.condition}</td>
                        <td className='backgroundCell2'>{item2.condition}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Baujahr</td>
                        <td className='backgroundCell1'>{item1.yearConstructed}</td>
                        <td className='backgroundCell2'>{item2.yearConstructed}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Anzahl Parkplätze</td>
                        <td className='backgroundCell1'>{item1.noParkSpaces}</td>
                        <td className='backgroundCell2'>{item2.noParkSpaces}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Hat Balkon oder Garten?</td>
                        <td className='backgroundCell1'>{item1.gardenOrBalcony}</td>
                        <td className='backgroundCell2'>{item2.gardenOrBalcony}</td>
                    </tr>
                </>
            )
        }

        return (
            <>
                <div>
                    <h1 className='largeHeader'>Wohnungspaare vergleichen</h1>
                </div>
                <div>
                    <p className='text'>
                        Unten werden zwei Wohnungen gezeigt.
                        Bitte wähle diejenige Wohnung aus, die besser zu den Präferenzen von Familie Fischer passt.
                        Verwende den Slider, um die bessere Wohnung auszuwählen.
                        <br/><br/>
                        Wenn Du dir unsicher bist über die Präferenzen von Familie Fischer,
                        kannst Du nach unten scrollen und die Beschreibung nochmals durchlesen.
                    </p>

                    <p style={{paddingLeft: '40px'}}>
                        Wohnungspaar {this.state.counter} von 55:
                    </p>
                </div>

                {this.state.itemPresent ?
                    <>
                        <div style={{paddingLeft: '40px'}}>
                            <Table bordered striped>
                                <thead>
                                <tr>
                                    <th style={{width: '300px'}}>Attribute</th>
                                    <th className='backgroundHeader1' style={{width: '300px'}}>Option A</th>
                                    <th className='backgroundHeader2' style={{width: '300px'}}>Option B</th>
                                </tr>
                                </thead>
                                <tbody>
                                {table.map((element) => (
                                    (element)))}
                                </tbody>
                            </Table>
                        </div>

                        {/* <div style={{paddingLeft: '40px', paddingTop: '40px', paddingBottom: '40px'}}>
                            <div style={{display: 'inline', paddingLeft: '410px'}}>
                                <button className='button decisionButton1' onClick={() => {
                                    this.handleSelectItem(true)
                                }}>Option A
                                </button>
                            </div>
                            <div style={{display: 'inline', paddingLeft: '211px'}}>
                                <button className='button decisionButton2' onClick={() => {
                                    this.handleSelectItem(false)
                                }}>Option B
                                </button>
                            </div>
                        </div> */}
                    </>
                    :
                    <div style={{height: '490px', paddingTop: '40px', paddingBottom: '40px', paddingLeft: '40px'}}>
                        <div style={{paddingTop: '200px', paddingLeft: '400px'}}>Finding a new pair</div>
                    </div>}

                    <div>
                    <p className='text'>
                        Bitte gib an, welche Option besser ist und um wie viel besser sie ist, indem Du den Schieberegler nach links und rechts verschiebst.
                    </p>
                </div>

                {/* Sliders start */}
                  

                <div style={{width:'800px', marginLeft:'90px'}}>
               
                 <Slider
                    key={this.state.counter}
                    aria-label="Custom marks"
                    defaultValue={0}
                    step={0.01}
                    min={-1}
                    max={0.99}
                    valueLabelDisplay="off"
                    onChangeCommitted={ (event, value) => this.state.optionValue = value}
                    track={false}
                    size={'medium'}
                    sx={{height: 7, '& .MuiSlider-mark': {
                        width: 2,
                        height:12}, '& .MuiSlider-track': {
                            border: 'none',}}}
                    marks={optionValueSliderMarks}
                />

                </div>

                <div>
                    <p className='text'>
                    Bitte gib an, wie sicher Du bei deiner Entscheidung bist
                    </p>
                </div>

                <div style={{width:'800px', marginLeft:'90px'}}>
 
                    <Slider
                    key={this.state.counter}
                    aria-label="Custom marks"
                    defaultValue={50}
                    step={1}
                    min={0}
                    max={100}
                    valueLabelDisplay="off"
                    onChangeCommitted={ (event, value) => this.state.certainity = value}
                    track={false}
                    size={'medium'}
                    sx={{height: 7, '& .MuiSlider-mark': {
                        width: 2,
                        height:12}, '& .MuiSlider-track': {
                            border: 'none',}}}
                    marks={confidenceSliderMarks}
                />
                </div>

                {/* Sliders end */}

                <div>
                    <p className='text'>
                        Wenn Du auf "Bestätigen" klickst, wird Deine Antwort gespeichert und das nächste Paar wird Dir gezeigt (falls noch Paare übrig sind).
                    </p>
                </div>

                <div style={{paddingLeft: '40px', paddingTop: '0px', paddingBottom: '10px', textAlign: 'center', width: '900px'}}>
                               
                    <button className='button confirmButton' onClick={() => {
                            this.handleSelectItem()
                        }}>Bestätigen
                    </button>

                </div>

                <div>
                <p className='taskDescription'>
                        Du bist ein Immobilienmakler in Deutschland und Deine Aufgabe ist es, die beste Wohnung für
                        Familie Fischer zu finden. Als Hilfe findest Du hier eine kurze Beschreibung der Familie und
                        was für sie wichtig ist bei ihrer neuen Wohnung.
                        <br/><br/>
                        Zur Familie Fischer gehören die 38-jährige Mutter Barbara, ihr 36-jähriger Ehemann Hugo und die
                        beiden Töchter Ella (10 Jahre) und Lisa (3 Jahre).
                        <br/><br/>
                        Die Wünsche von Familie Fischer an ihre neue Wohnung lauten wie folgt:
                        <br/><br/>
                        1. Lage: Es ist sehr wichtig, dass die neue Wohnung in einem der folgenden <b>Landeskreise</b>
                        {' '} liegt, da Herr Fischer in der Nähe arbeitet: <b>Stuttgart</b> (am besten), {' '}
                        <b>Ludwigsburg_Kreis</b> {' '} (sehr gut), <b>Esslingen_Kreis</b> (gut), oder {' '}
                        <b>Rems-Murr_Kreis</b> (gut).
                        <br/><br/>
                        2. Mietkosten: Es ist sehr wichtig, dass die neue Wohnung <b>nicht zu viel und nicht zu wenig
                        kostet</b>. Familie Fischer hat ein Budget von circa <b>1300 Euro</b> (plus minus 300 Euro) für
                        die Miete der neuen Wohnung reserviert.
                        <br/><br/>
                        3. Nebenkosten: Es ist wichtig, dass die <b>Nebenkosten</b> der neuen Wohnung <b>so tief wie
                        möglich</b> {' '} sind. Bereits kleine Unterschiede bei den Nebenkosten machen einen
                        überdurchschnittlich grossen Unterschied aus für Familie Fischer.
                        <br/><br/>
                        4. Anzahl Zimmer: Es ist wichtig, dass die Wohnung <b>genügend Zimmer</b> hat. Idealerweise
                        sollte die Wohnung {' '} <b>4 Zimmer</b> haben, damit genug Platz für alle da ist. Auch 4.5 oder
                        5 Zimmer wären noch gut, mehr jedoch nicht. 3.5 Zimmer sind das Minimum, welches noch ok wäre
                        für die Familie.
                        <br/><br/>
                        5. Wohnfläche: Es wäre gut, wenn die Wohnung ungefähr <b>150 m&#178; Wohnfläche</b> hat.
                        30 m&#178; weniger oder 50 m&#178; mehr Wohnfläche sind die Unter- und Obergrenze, die für die
                        Familie noch akzeptabel wäre. Die Wohnung sollte jedoch nicht zu gross sein, da der Putzaufwand
                        ansonsten zu gross ist. Und die Wohnung darf auch nicht zu klein sein, da sie sonst zu wenig
                        Platz bietet.
                        <br/><br/>
                        6. Stockwerk und Lift: Es wäre gut, wenn die Wohnung im <b>Erdgeschoss</b> oder im <b>ersten Stock</b>
                        {' '} liegt und das Haus idealerweise <b>einen Aufzug</b> hat, da die jüngste Tochter noch einen
                        Kinderwagen braucht. Wenn die Wohnung einen Aufzug hat, wäre der zweite oder dritte Stock für
                        die Familie akzeptabel.
                        <br/><br/>
                        7. Zustand der Wohnung: Weniger wichtig ist der <b>Zustand der Wohnung</b>. Da die Familie noch
                        kleine Kinder hat, möchten sie nicht in einen Neubau ziehen (first time use) oder eine
                        neu renovierte Wohnung (fully renovated). Es reicht vollkommen aus, wenn die Wohnung in
                        einem guten Zustand ist (<b>mint condition, well kept)</b> oder aufgefrischt wurde
                        (<b>refurbished</b>).
                        <br/><br/>
                        8. Baujahr: Weniger wichtig ist das <b>Baujahr</b> der Wohnung. Da Herr Fischer Altbauwohnungen
                        liebt, würde er sich freuen, wenn die Familie in eine Wohnung ziehen könnte, die <b>vor 1900
                        gebaut wurde</b>. Falls das nicht möglich ist, sollte die Wohnung <b>so neu wie möglich</b> sein.
                        <br/><br/>
                        9. Anzahl Parkplätze: Nicht wichtig ist, <b>wie viele Parkplätze</b> zur Wohnung gehören.
                        Im Moment hat Familie Fischer kein Auto. Ab und zu werden sie sicherlich von Freunden oder
                        Verwandten besucht, die mit dem Auto vorbeikommen. Falls die Wohnung <b>einen oder keinen
                        Parkplatz</b> hat, wäre das ok für Familie Fischer.
                        <br/><br/>
                        10. Balkon und Garten: Nicht wichtig ist, ob die Wohnung einen <b>Balkon oder Garten</b> hat.
                        Familie Fischer fände es schön, wenn die Wohnung einen <b>Balkon und Garten</b> hätte. Falls
                        nicht beides möglich ist, würde sie einen <b>Garten</b> bevorzugen.
                        <br/><br/>
                        Alle anderen Charakteristiken sind für Familie Fischer nicht wichtig bei ihrer Wohnungssuche.
                    </p>
                </div>
                <div style={{paddingBottom:"100px"}}>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default PairwiseComparisonDE;