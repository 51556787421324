import React from 'react';
import {Link} from "react-router-dom";
import FooterSticky from "./FooterSticky";

class TaskOverviewEN extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            userId: this.props.location.state.userId
        };
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>Task Overview I</h1>
                </div>

                <div>
                    <p className='text'>
                        In this task you are an estate agent and you have to find a suitable flat for a family.
                        The family has some preferences about their new flat, which are presented to you on the next
                        page.
                        <br/><br/>
                        Your task is to choose from a pair of flats the one that you think fits the family's
                        preferences better.
                        You will be shown a total of 55 pairs of flats and you must choose the better flat in each pair.
                        <br/><br/>
                        To continue, please click Next.
                    </p>
                </div>

                <div style={{paddingTop: '2%', paddingBottom: '10%', paddingLeft: '40px', height: '100%',}}>
                    <Link
                        to={{
                            pathname: '/taskDetailEN',
                            state:{
                                userId: this.state.userId
                            }
                        }}>
                        <button className='button goButton'>Next</button>
                    </Link>
                </div>
                <div style={{paddingBottom: '40px'}}>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default TaskOverviewEN;