import React from 'react';
import Table from "react-bootstrap/Table";
import Footer from "./Footer";
import {getRandomArbitrary} from "../HelperFunctions";
import Slider from "@mui/material/Slider";
import FooterSticky from './FooterSticky';


class PairwiseComparisonEN extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            counter: 1, //The counter that counts, how many item pairs have been processed
            itemPresent: false, //Boolean that indicates whether an item pair is present or not
            itemPair: [], //An item pair that was loaded from the server
            // userId: 0, //The user id
            userId: this.props.location.state.userId,
            certainity: 50, // Certainity of choice
            completedTask: false, // Flag to check if user completed the task
            ipaddress: "undefined", // Initialize variable for storing IP address
            optionValue: 0, // Option value
            email_id: "NA"

        };
        this.getItemPair = this.getItemPair.bind(this)
        this.getItemPairUserCalibration = this.getItemPairUserCalibration.bind(this)
    }

    /**
     * Life-cycle method that initializes the user Id and starts the ranking task.
     */
    componentWillMount() {
        // this.setState({userId: getRandomArbitrary(1000000, 2000000)}, () => this.getItemPair());
        console.log(this.state.counter)
        this.getItemPair();

    // Fetch IP address using external API (cannot do through local API)
    fetch("https://api.ipify.org?format=json&callback=getIP")
        .then((response) => response.json())
        .then(res => {
            this.state.ipaddress = String(res.ip)
        })
        .catch(err => console.log(err))
    }

    /**
     * Increases the counter that counts, how many item pairs have already been processed
     * If 50 items have been processed, the user is redirected to the end page
     */
    increaseCounter() {

        if (this.state.counter === 54)
        {
            this.state.completedTask = true
        }
        //Check if the user has already processed 50 pairs
        if (this.state.counter === 55) {
            //If yes, task completion for the user is recorded and the user is redirected to the end page
            let path = {
                pathname: '/finishedEN',
                state:{
                    userId: this.state.userId
                }
            }
            this.props.history.push(path);
        }
        if (((this.state.counter + 1) % 10) == 0) {
            console.log(this.state.counter)
            console.log("User calibration pair")
            this.setState({counter: this.state.counter + 1}, () => this.getItemPairUserCalibration())

        }
        else {
            console.log("Normal counter", this.state.counter)
            //Else, a new pair is requested from the server
            this.setState({counter: this.state.counter + 1}, () => this.getItemPair())
        }
    }

    /**
     * Request an item pair from the server.
     */
    getItemPair() {
        //Specify the options for the GET request
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        // Fetch the item pair. Be careful with the address and do not confuse localhost and 127.0.0.1
        // fetch("http://127.0.0.1:4000/pair?userId=" + this.state.userId, requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/pair?userId=" + this.state.userId, requestOptions)
            .then(res => res.json())
            .then(
                //Set the item pair as state
                (result) => {
                    this.setState({
                        itemPresent: true,
                        itemPair: result.pair
                    });
                },
                //Handle errors
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    /**
     * Request an item pair from the server.
     */
     getItemPairUserCalibration() {
        //Specify the options for the GET request
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        console.log("Getting user calibration item")

    
        //Fetch the item pair. Be careful with the address and do not confuse localhost and 127.0.0.1
        // fetch("http://127.0.0.1:4000/UCpair?userId=" + this.state.userId, requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/UCpair?userId=" + this.state.userId, requestOptions)
            .then(res => res.json())
            .then(
                //Set the item pair as state
                (result) => {
                    console.log(result)
                    this.setState({
                        itemPresent: true,
                        itemPair: result.pair
                    });
                },
                //Handle errors
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    /**
     * When a user has clicked on the button of option , this method is called
     * @param optionValue Float that indicates whether the first (less than 0) or second item (greater than 0) in the pair was chosen
     */
     handleSelectItem() {
        this.setState({itemPresent: false},
            () => {
                if (this.state.optionValue < 0) {
                    console.log("Sending", this.state.certainity)
                    let optionSelected = "Option A"
                    if (this.state.counter % 10 == 0) {
                        this.sendChoiceToServerUserCalibration(this.state.itemPair[0], this.state.itemPair[1], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                    else {
                        this.sendChoiceToServer(this.state.itemPair[0], this.state.itemPair[1], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                    
                } else if (this.state.optionValue >= 0) {
                    let optionSelected = "Option B"
                    if (this.state.counter % 10 == 0) {
                        this.sendChoiceToServerUserCalibration(this.state.itemPair[1], this.state.itemPair[0], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                    else {
                        this.sendChoiceToServer(this.state.itemPair[1], this.state.itemPair[0], this.state.optionValue, optionSelected, this.state.certainity)
                    }
                   
                }
            }
        )
    }

    /**
     * Sends the chosen item to the server for storing it in the database
     * @param picked the item that was picked by the user
     * @param notPicked the item that was not picked by the user
     * @param optionValue the value of the slider for selecting Option A or Option B
     * @param OptionSelected the string of option A or option B selected
     * @param certainity the value from the certainity slider
     */
    sendChoiceToServer(picked, notPicked, optionValue, optionSelected, certainity) {
        //Specify the request options
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'userId': this.state.userId, 'itemIdPicked': picked.itemId,
                'itemIdNotPicked': notPicked.itemId,
                'optionValue': optionValue,
                'optionSelected': optionSelected,
                'certainitySlider': this.state.certainity,
                'completedTask': this.state.completedTask,
                'ipaddress': this.state.ipaddress,
                'email_id': this.state.email_id

            })
        };

        //Send the request to the server
        // fetch("http://127.0.0.1:4000/choice", requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/choice", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    //Increase the counter if the call was successful
                    this.increaseCounter();
                    // Reset sliders
                    this.state.certainity = 50
                    this.state.optionValue = 0
                },
                //Handle errors otherwise
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    /**
     * Sends the chosen item to the server for storing it in the database
     * @param picked the item that was picked by the user
     * @param notPicked the item that was not picked by the user
     */
     sendChoiceToServerUserCalibration(picked, notPicked, optionValue, optionSelected, certainity) {
        //Specify the request options
        console.log("Picked",picked.UCitemId)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'UCuserId': this.state.userId,
                'UCitemIdPicked': picked.UCitemId,
                'UCitemIdNotPicked': notPicked.UCitemId,
                'UCoptionValue': optionValue,
                'UCoptionSelected': optionSelected,
                "UCcertainitySlider": this.state.certainity
            })
            //TODO: Should be POST and not PUT
        };

        //Send the request to the server
        // fetch("http://127.0.0.1:4000/UCchoice", requestOptions)
        fetch("https://ranking-ivda.ifi.uzh.ch/backend/UCchoice", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    //Increase the counter if the call was successful
                    this.increaseCounter();
                    // Reset sliders
                    this.state.certainity = 50
                    this.state.optionValue = 0
                },
                //Handle errors otherwise
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    render() {
        let table = [];

        const optionValueSliderMarks = [
            {
                value: -1,
                label: "Option A is a lot better"
            },
            {
                value: -0.5,
                label: "Option A is a little better"
            },
            {
                value: 0,
                label: "Both are equal"
            },
            {
                value: 0.5,
                label: "Option B is a little better"
            },
            {
                value: 0.99,
                label: "Option B is a lot better"
            }
        ];

        const confidenceSliderMarks = [
            {
                value: 0,
                label: "Not confident at all"
            },
            {
                value: 50,
                label: "More or less confident"
            },
            {
                value: 100,
                label: "Very confident"
            }
        ];

        //Create the table that shows the two options
        console.log("Table counter", this.state.counter)
        if (this.state.itemPresent && ((this.state.counter % 10) == 0)) {
            let item1 = this.state.itemPair[0];
            let item2 = this.state.itemPair[1];
            table.push(
                <>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Location</td>
                        <td className='backgroundCell1'>{item1.UCregio2}</td>
                        <td className='backgroundCell2'>{item2.UCregio2}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Rent</td>
                        <td className='backgroundCell1'>{item1.UCbaseRent}</td>
                        <td className='backgroundCell2'>{item2.UCbaseRent}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Additional costs</td>
                        <td className='backgroundCell1'>{item1.UCserviceCharge}</td>
                        <td className='backgroundCell2'>{item2.UCserviceCharge}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Number of rooms</td>
                        <td className='backgroundCell1'>{item1.UCnoRooms}</td>
                        <td className='backgroundCell2'>{item2.UCnoRooms}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Living space</td>
                        <td className='backgroundCell1'>{item1.UClivingSpace}</td>
                        <td className='backgroundCell2'>{item2.UClivingSpace}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Floor number and lift?</td>
                        <td className='backgroundCell1'>{item1.UCliftAndFloor}</td>
                        <td className='backgroundCell2'>{item2.UCliftAndFloor}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Condition</td>
                        <td className='backgroundCell1'>{item1.UCcondition}</td>
                        <td className='backgroundCell2'>{item2.UCcondition}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Year of construction</td>
                        <td className='backgroundCell1'>{item1.UCyearConstructed}</td>
                        <td className='backgroundCell2'>{item2.UCyearConstructed}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Number of parking spaces</td>
                        <td className='backgroundCell1'>{item1.UCnoParkSpaces}</td>
                        <td className='backgroundCell2'>{item2.UCnoParkSpaces}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Has a garden or balcony?</td>
                        <td className='backgroundCell1'>{item1.UCgardenOrBalcony}</td>
                        <td className='backgroundCell2'>{item2.UCgardenOrBalcony}</td>
                    </tr>
                </>
            )
        }

        else if (this.state.itemPresent) {

            let item1 = this.state.itemPair[0];
            let item2 = this.state.itemPair[1];
            table.push(
                <>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Location</td>
                        <td className='backgroundCell1'>{item1.regio2}</td>
                        <td className='backgroundCell2'>{item2.regio2}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Rent</td>
                        <td className='backgroundCell1'>{item1.baseRent}</td>
                        <td className='backgroundCell2'>{item2.baseRent}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Additional costs</td>
                        <td className='backgroundCell1'>{item1.serviceCharge}</td>
                        <td className='backgroundCell2'>{item2.serviceCharge}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Number of rooms</td>
                        <td className='backgroundCell1'>{item1.noRooms}</td>
                        <td className='backgroundCell2'>{item2.noRooms}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Living space</td>
                        <td className='backgroundCell1'>{item1.livingSpace}</td>
                        <td className='backgroundCell2'>{item2.livingSpace}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Floor number and lift?</td>
                        <td className='backgroundCell1'>{item1.liftAndFloor}</td>
                        <td className='backgroundCell2'>{item2.liftAndFloor}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Condition</td>
                        <td className='backgroundCell1'>{item1.condition}</td>
                        <td className='backgroundCell2'>{item2.condition}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Year of construction</td>
                        <td className='backgroundCell1'>{item1.yearConstructed}</td>
                        <td className='backgroundCell2'>{item2.yearConstructed}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Number of parking spaces</td>
                        <td className='backgroundCell1'>{item1.noParkSpaces}</td>
                        <td className='backgroundCell2'>{item2.noParkSpaces}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>Has a garden or balcony?</td>
                        <td className='backgroundCell1'>{item1.gardenOrBalcony}</td>
                        <td className='backgroundCell2'>{item2.gardenOrBalcony}</td>
                    </tr>
                </>
            )

        }

        return (
            <>
                <div>
                    <h1 className='largeHeader'>Pairwise Comparison</h1>
                </div>
                <div>
                    <p className='text'>
                        Two flats are shown below.
                        Please use the slider to indicate which flat better suits the family's preferences.

                        If you are unsure on the preferences of family Fischer, you can scroll to the bottom and read
                        the description again.
                    </p>
                    <p style={{paddingLeft: '40px'}}>
                        Pair number {this.state.counter} of 55:
                    </p>
                </div>
                {this.state.itemPresent ?
                    <>
                        <div style={{paddingLeft: '40px'}}>
                            <Table bordered striped>
                                <thead>
                                <tr>
                                    <th style={{width: '300px'}}>Attribute</th>
                                    <th className='backgroundHeader1' style={{width: '300px'}}>Option A</th>
                                    <th className='backgroundHeader2' style={{width: '300px'}}>Option B</th>
                                </tr>
                                </thead>
                                <tbody>
                                {table.map((element) => (
                                    (element)))}
                                </tbody>
                            </Table>
                        </div>
                            <div style={{paddingLeft: '40px', paddingTop: '0px', paddingBottom: '0px'}}>
                                {/* <div style={{display: 'inline', paddingLeft: '410px'}}>
                                    <button className='button decisionButton1' onClick={() => {
                                        console.log(document.getElementById("certainity").value)
                                        this.state.certainity = document.getElementById("certainity").value
                                        this.handleSelectItem(true)
                                    }}>Option A
                                    </button>
                                </div>
                                <div style={{display: 'inline', paddingLeft: '211px'}}>
                                    <button className='button decisionButton2' onClick={() => {
                                        console.log(document.getElementById("certainity").value)
                                        this.state.certainity = document.getElementById("certainity").value
                                        this.handleSelectItem(false)
                                    }}>Option B
                                    </button>
                                </div> */}

                                {/* <button className='button confirmButton' onClick={() => {
                                        console.log(document.getElementById("certainity").value)
                                        this.state.certainity = document.getElementById("certainity").value
                                        let optionValue = document.getElementById("optionValue").value
                                        this.handleSelectItem(optionValue)
                                    }}>Option A
                                </button> */}

                        </div>
                    </>
                    :
                    <div style={{height: '490px', paddingTop: '40px', paddingBottom: '40px', paddingLeft: '40px'}}>
                        <div style={{paddingTop: '200px', paddingLeft: '400px'}}>Finding a new pair</div>
                    </div>}

                <div>
                    <p className='text'>
                    Please indicate which option is better and how much it is better by dragging the slider to the left and right.
                    </p>
                </div>

                {/* Sliders start */}
                  

                <div style={{width:'800px', marginLeft:'90px'}}>
               
                 <Slider
                    key={this.state.counter}
                    aria-label="Custom marks"
                    defaultValue={0}
                    step={0.01}
                    min={-1}
                    max={0.99}
                    valueLabelDisplay="off"
                    onChangeCommitted={ (event, value) => this.state.optionValue = value}
                    track={false}
                    size={'medium'}
                    sx={{height: 7, '& .MuiSlider-mark': {
                        width: 2,
                        height:12}, '& .MuiSlider-track': {
                            border: 'none',}}}
                    marks={optionValueSliderMarks}
                />

                </div>

                <div>
                    <p className='text'>
                    Please indicate how confident you are with your decision
                    </p>
                </div>

                <div style={{width:'800px', marginLeft:'90px'}}>
 
                    <Slider
                    key={this.state.counter}
                    aria-label="Custom marks"
                    defaultValue={50}
                    step={1}
                    min={0}
                    max={100}
                    valueLabelDisplay="off"
                    onChangeCommitted={ (event, value) => this.state.certainity = value}
                    track={false}
                    size={'medium'}
                    sx={{height: 7, '& .MuiSlider-mark': {
                        width: 2,
                        height:12}, '& .MuiSlider-track': {
                            border: 'none',}}}
                    marks={confidenceSliderMarks}
                />
                </div>

                {/* Sliders end */}

                <div>
                    <p className='text'>
                    By clicking on the confirm button, your answers are stored and the next pair is shown to you (if there are any more pairs left).
                    </p>
                </div>

                <div style={{paddingLeft: '40px', paddingTop: '0px', paddingBottom: '10px', textAlign: 'center', width: '900px'}}>
                               
                    <button className='button confirmButton' onClick={() => {
                            this.handleSelectItem()
                        }}>Confirm
                    </button>

                </div>

                <div>
                <p className='taskDescription'>
                        You are a real estate agent in Germany and your task is to find the best flat for the Fischer
                        family. To help you, here is a brief description of the family and what is important to them in
                        their new home.
                        <br/><br/>
                        The Fischer family includes 38-year-old mother Barbara, her 36-year-old husband Hugo and their
                        two daughters Ella (10 years old) and Lisa (3 years old).
                        <br/><br/>
                        The Fischer family's wishes for their new flat are as follows:
                        <br/><br/>
                        1. Location: It is very important that the new flat is located in one of the following {' '}
                        <b>regions</b>, as Mr Fischer works nearby: <b>Stuttgart</b> (best), <b>Ludwigsburg_Kreis</b>
                        {' '}(very good), {' '} <b>Esslingen_Kreis</b> (good), or <b>Rems-Murr_Kreis</b> (good).
                        <br/><br/>
                        2. Rent: It is very important that the new flat does <b>not cost too much or too little</b>.
                        The Fischer family has reserved a budget of about <b>1300 euros</b> (plus or minus 300 euros)
                        for the base rent of the new flat.
                        <br/><br/>
                        3. Additional costs: It is important that the <b>additional costs</b> of the new flat <b>is as low as
                        possible</b>. Small differences in the additional costs make a disproportionate difference for
                        the Fischer family.
                        <br/><br/>
                        4. Number of rooms: It is important that the flat has <b>enough rooms</b>. Ideally, the flat
                        should have {' '} <b>4 rooms</b> so that there is enough space for everyone. Even 4.5 or 5
                        rooms would be good, but not more. 3.5 rooms are the minimum, which would still be ok for the
                        family.
                        <br/><br/>
                        5. Living Space: It would be good if the flat had about <b>150 m&#178;</b> of living space.
                        30m&#178; less or 50 m&#178; more living space are the lower and upper limits that would work
                        for the family. However, the flat should not be too big, otherwise the cleaning effort is too
                        much. And the flat should not be too small either, otherwise there is not enough space.
                        <br/><br/>
                        6. Floor number and lift: It would be good if the flat is on the <b>ground floor or first floor</b> and
                        the house ideally <b>has a lift</b>, as the youngest daughter still needs a stroller. If the
                        flat has a lift, the second or third floor would be acceptable for the family.
                        <br/><br/>
                        7. Condition: It is less important which <b>condition</b> the flat has. Since they still have small
                        children, they do not want to move into a new flat (first time use) or a fully renovated flat.
                        It is quite sufficient if the flat is in <b>good condition (mint condition or well kept)</b>
                        {' '} or has been <b>refurbished</b>.
                        <br/><br/>
                        8. Year of construction: It is less important when the flat <b>was built.</b> Since Mr Fischer loves
                        old buildings, he would be happy if the family could move into a flat that was built {' '}
                        <b>before 1900</b>. If this is not possible, the flat should be as <b>new as possible</b>.
                        <br/><br/>
                        9. Parking space: It is not really important whether the flat comes with a <b>parking space.</b>  {' '}
                        At the moment, the Fischer family does not have a car.
                        From time to time, they will certainly be visited by friends or relatives who come over by car.
                        If the flat has <b>one or no parking spaces</b>, that would be ok for the Fischer family.
                        <br/><br/>
                        10. Balcony and garden: It is not really important whether the flat has a <b>balcony or garden.</b>
                        The Fischer family would like if the flat has a <b>balcony and a garden</b>. {' '}
                        If both are not possible, they would prefer a <b>garden</b>.
                        <br/><br/>
                        All other apartment characteristics are not important for the Fischer family when looking for
                        a flat.
                    </p>
                </div>
                <div style={{paddingBottom:"100px"}}>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default PairwiseComparisonEN;