import React from 'react';
import {Link} from "react-router-dom";
import FooterSticky from './FooterSticky';

class TaskDetailEN extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.location.state.userId,
        };
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>Task Overview II</h1>
                </div>

                <div>
                    <p className='taskDescription'>
                        You are a real estate agent in Germany and your task is to find the best flat for the Fischer
                        family. To help you, here is a brief description of the family and what is important to them in
                        their new home.
                        <br/><br/>
                        The Fischer family includes 38-year-old mother Barbara, her 36-year-old husband Hugo and their
                        two daughters Ella (10 years old) and Lisa (3 years old).
                        <br/><br/>
                        The Fischer family's wishes for their new flat are as follows:
                        <br/><br/>
                        1. Location: It is very important that the new flat is located in one of the following {' '}
                        <b>regions</b>, as Mr Fischer works nearby: <b>Stuttgart</b> (best), <b>Ludwigsburg_Kreis</b>
                        {' '}(very good), {' '} <b>Esslingen_Kreis</b> (good), or <b>Rems-Murr_Kreis</b> (good).
                        <br/><br/>
                        2. Rent: It is very important that the new flat does <b>not cost too much or too little</b>.
                        The Fischer family has reserved a budget of about <b>1300 euros</b> (plus or minus 300 euros)
                        for the base rent of the new flat.
                        <br/><br/>
                        3. Additional costs: It is important that the <b>additional costs</b> of the new flat <b>is as low as
                        possible</b>. Small differences in the additional costs make a disproportionate difference for
                        the Fischer family.
                        <br/><br/>
                        4. Number of rooms: It is important that the flat has <b>enough rooms</b>. Ideally, the flat
                        should have {' '} <b>4 rooms</b> so that there is enough space for everyone. Even 4.5 or 5
                        rooms would be good, but not more. 3.5 rooms are the minimum, which would still be ok for the
                        family.
                        <br/><br/>
                        5. Living Space: It would be good if the flat had about <b>150 m&#178;</b> of living space.
                        30m&#178; less or 50 m&#178; more living space are the lower and upper limits that would work
                        for the family. However, the flat should not be too big, otherwise the cleaning effort is too
                        much. And the flat should not be too small either, otherwise there is not enough space.
                        <br/><br/>
                        6. Floor number and lift: It would be good if the flat is on the <b>ground floor or first floor</b> and
                        the house ideally <b>has a lift</b>, as the youngest daughter still needs a stroller. If the
                        flat has a lift, the second or third floor would be acceptable for the family.
                        <br/><br/>
                        7. Condition: It is less important which <b>condition</b> the flat has. Since they still have small
                        children, they do not want to move into a new flat (first time use) or a fully renovated flat.
                        It is quite sufficient if the flat is in <b>good condition (mint condition or well kept)</b>
                        {' '} or has been <b>refurbished</b>.
                        <br/><br/>
                        8. Year of construction: It is less important when the flat <b>was built.</b> Since Mr Fischer loves
                        old buildings, he would be happy if the family could move into a flat that was built {' '}
                        <b>before 1900</b>. If this is not possible, the flat should be as <b>new as possible</b>.
                        <br/><br/>
                        9. Parking space: It is not really important whether the flat comes with a <b>parking space.</b>  {' '}
                        At the moment, the Fischer family does not have a car.
                        From time to time, they will certainly be visited by friends or relatives who come over by car.
                        If the flat has <b>one or no parking spaces</b>, that would be ok for the Fischer family.
                        <br/><br/>
                        10. Balcony and garden: It is not really important whether the flat has a <b>balcony or garden.</b>
                        The Fischer family would like if the flat has a <b>balcony and a garden</b>. {' '}
                        If both are not possible, they would prefer a <b>garden</b>.
                        <br/><br/>
                        All other apartment characteristics are not important for the Fischer family when looking for
                        a flat.
                    </p>
                </div>

                <div style={{paddingTop: '20px', paddingBottom: '150px', paddingLeft: '40px', height: '100%',}}>
                    <Link
                        to={{
                            pathname: '/pairwiseComparisonEN',
                            state:{
                                userId: this.state.userId
                            }
                        }}>
                        <button className='button goButton'>Next</button>
                    </Link>
                </div>
                <div>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default TaskDetailEN;