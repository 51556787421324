import {Link} from 'react-router-dom';
import FooterSticky from './FooterSticky';

const Home = () => {

    return (
        <>
            <div>
                <h1 className='largeHeader'>Pairwise Ranking Tool</h1>
            </div>
            <div>
                <h2 style={{paddingTop: '10px', paddingLeft: '40px', height: '100%',}}> English Version</h2>
                <p className='text'>
                    Welcome to the pairwise ranking study of the University of Zurich.
                    <br/>
                    <br/>
                    To start the tool in English please click on the <b>Start (EN)</b> button below.
                </p></div>
            <div style={{paddingTop: '10px', paddingBottom: '80px', paddingLeft: '40px', height: '100%',}}>
                <Link to={{pathname: '/introductionEN',}}>
                    <button className='button goButton'>Start (EN)</button>
                </Link>
            </div>
            <div>
                <h2 style={{paddingLeft: '40px', height: '100%',}}> Deutsche Version</h2>
                <p className='text'>
                    Herzlich willkommen zur Pairwise Ranking Studie der Universität Zürich.
                    <br/>
                    <br/>
                    Um die Studie auf Deutsch zu starten, klicke bitte auf den <b>Start (DE)</b> Button.
                </p></div>
            <div style={{paddingTop: '2%', paddingBottom: '150px', paddingLeft: '40px', height: '100%',}}>
                <Link to={{pathname: '/introductionDE',}}>
                    <button className='button goButton'>Start (DE)</button>
                </Link>
            </div>
            <div>
                <FooterSticky/>
            </div>
        </>
    )
}

export default Home;


