import React from 'react';
import {Link} from "react-router-dom";
import FooterSticky from './FooterSticky';

class TaskDetailDE extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.location.state.userId,
        };
    }

    render() {
        return (
            <>
                <div>
                    <h1 className='largeHeader'>Aufgabenbeschreibung II</h1>
                </div>

                <div>
                    <p className='taskDescription'>
                        Du bist ein Immobilienmakler in Deutschland und Deine Aufgabe ist es, die beste Wohnung für
                        Familie Fischer zu finden. Als Hilfe findest Du hier eine kurze Beschreibung der Familie und
                        was für sie wichtig ist bei ihrer neuen Wohnung.
                        <br/><br/>
                        Zur Familie Fischer gehören die 38-jährige Mutter Barbara, ihr 36-jähriger Ehemann Hugo und die
                        beiden Töchter Ella (10 Jahre) und Lisa (3 Jahre).
                        <br/><br/>
                        Die Wünsche von Familie Fischer an ihre neue Wohnung lauten wie folgt:
                        <br/><br/>
                        1. Lage: Es ist sehr wichtig, dass die neue Wohnung in einem der folgenden <b>Landeskreise</b>
                        {' '} liegt, da Herr Fischer in der Nähe arbeitet: <b>Stuttgart</b> (am besten), {' '}
                        <b>Ludwigsburg_Kreis</b> {' '} (sehr gut), <b>Esslingen_Kreis</b> (gut), oder {' '}
                        <b>Rems-Murr_Kreis</b> (gut).
                        <br/><br/>
                        2. Mietkosten: Es ist sehr wichtig, dass die neue Wohnung <b>nicht zu viel und nicht zu wenig
                        kostet</b>. Familie Fischer hat ein Budget von circa <b>1300 Euro</b> (plus minus 300 Euro) für
                        die Miete der neuen Wohnung reserviert.
                        <br/><br/>
                        3. Nebenkosten: Es ist wichtig, dass die <b>Nebenkosten</b> der neuen Wohnung <b>so tief wie
                        möglich</b> {' '} sind. Bereits kleine Unterschiede bei den Nebenkosten machen einen
                        überdurchschnittlich grossen Unterschied aus für Familie Fischer.
                        <br/><br/>
                        4. Anzahl Zimmer: Es ist wichtig, dass die Wohnung <b>genügend Zimmer</b> hat. Idealerweise
                        sollte die Wohnung {' '} <b>4 Zimmer</b> haben, damit genug Platz für alle da ist. Auch 4.5 oder
                        5 Zimmer wären noch gut, mehr jedoch nicht. 3.5 Zimmer sind das Minimum, welches noch ok wäre
                        für die Familie.
                        <br/><br/>
                        5. Wohnfläche: Es wäre gut, wenn die Wohnung ungefähr <b>150 m&#178; Wohnfläche</b> hat.
                        30 m&#178; weniger oder 50 m&#178; mehr Wohnfläche sind die Unter- und Obergrenze, die für die
                        Familie noch akzeptabel wäre. Die Wohnung sollte jedoch nicht zu gross sein, da der Putzaufwand
                        ansonsten zu gross ist. Und die Wohnung darf auch nicht zu klein sein, da sie sonst zu wenig
                        Platz bietet.
                        <br/><br/>
                        6. Stockwerk und Lift: Es wäre gut, wenn die Wohnung im <b>Erdgeschoss</b> oder im <b>ersten Stock</b>
                        {' '} liegt und das Haus idealerweise <b>einen Aufzug</b> hat, da die jüngste Tochter noch einen
                        Kinderwagen braucht. Wenn die Wohnung einen Aufzug hat, wäre der zweite oder dritte Stock für
                        die Familie akzeptabel.
                        <br/><br/>
                        7. Zustand der Wohnung: Weniger wichtig ist der <b>Zustand der Wohnung</b>. Da die Familie noch
                        kleine Kinder hat, möchten sie nicht in einen Neubau ziehen (first time use) oder eine
                        neu renovierte Wohnung (fully renovated). Es reicht vollkommen aus, wenn die Wohnung in
                        einem guten Zustand ist (<b>mint condition, well kept)</b> oder aufgefrischt wurde
                        (<b>refurbished</b>).
                        <br/><br/>
                        8. Baujahr: Weniger wichtig ist das <b>Baujahr</b> der Wohnung. Da Herr Fischer Altbauwohnungen
                        liebt, würde er sich freuen, wenn die Familie in eine Wohnung ziehen könnte, die <b>vor 1900
                        gebaut wurde</b>. Falls das nicht möglich ist, sollte die Wohnung <b>so neu wie möglich</b> sein.
                        <br/><br/>
                        9. Anzahl Parkplätze: Nicht wichtig ist, <b>wie viele Parkplätze</b> zur Wohnung gehören.
                        Im Moment hat Familie Fischer kein Auto. Ab und zu werden sie sicherlich von Freunden oder
                        Verwandten besucht, die mit dem Auto vorbeikommen. Falls die Wohnung <b>einen oder keinen
                        Parkplatz</b> hat, wäre das ok für Familie Fischer.
                        <br/><br/>
                        10. Balkon und Garten: Nicht wichtig ist, ob die Wohnung einen <b>Balkon oder Garten</b> hat.
                        Familie Fischer fände es schön, wenn die Wohnung einen <b>Balkon und Garten</b> hätte. Falls
                        nicht beides möglich ist, würde sie einen <b>Garten</b> bevorzugen.
                        <br/><br/>
                        Alle anderen Charakteristiken sind für Familie Fischer nicht wichtig bei ihrer Wohnungssuche.
                    </p>
                </div>

                <div style={{paddingTop: '20px', paddingBottom: '150px', paddingLeft: '40px', height: '100%',}}>
                    <Link
                        to={{
                            pathname: '/pairwiseComparisonDE',
                            state:{
                                userId: this.state.userId
                            }
                        }}>
                        <button className='button goButton'>Weiter</button>
                    </Link>
                </div>
                <div>
                    <FooterSticky/>
                </div>
            </>
        );
    }
}

export default TaskDetailDE;